import { ChainId, JSBI, Percent, Token, WNATIVE, WBNB } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { robTestnetTokens, robMainnetTokens, USDT, BUSD } from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.ROBIN]: '0x2168c50396130C0DaB8250159484541487bd66d3',
  [ChainId.ROBIN_TESTNET]: '0x5A84eC2ED6B332B98a7Bad83ab285a1591287364', // 路由合约V2
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    WNATIVE[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    BUSD[ChainId.ETHEREUM],
    WBNB[ChainId.ETHEREUM],
  ],
  [ChainId.ROBIN]: [],
  // [
  // robMainnetTokens.wrpg,
  // robMainnetTokens.mix,
  // robMainnetTokens.usdt,
  // robMainnetTokens.amg
  // ],
  [ChainId.ROBIN_TESTNET]: [],
  // [
  // robTestnetTokens.rpg,
  // robTestnetTokens.cake,
  // robTestnetTokens.btc,
  // robTestnetTokens.bnb,
  // robTestnetTokens.matic,
  // robTestnetTokens.ada,
  // robTestnetTokens.sol,
  // robTestnetTokens.usdt,
  // ],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.ROBIN]: {},
  [ChainId.ROBIN_TESTNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.ROBIN]: {},
  [ChainId.ROBIN_TESTNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.ETHEREUM]: [BUSD[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  [ChainId.ROBIN]: [robMainnetTokens.wrpg, robMainnetTokens.mix, robMainnetTokens.usdt],
  [ChainId.ROBIN_TESTNET]: [robTestnetTokens.cake, robTestnetTokens.btc, robTestnetTokens.usdt],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    WNATIVE[ChainId.ETHEREUM],
    BUSD[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WBNB[ChainId.ETHEREUM],
  ],
  [ChainId.ROBIN]: [],
  [ChainId.ROBIN_TESTNET]: [],
  // [ChainId.ROBIN]: [
  //   robMainnetTokens.wrpg,
  //   robMainnetTokens.mix,
  //   robMainnetTokens.usdt,
  //   robMainnetTokens.amg
  // ],
  // [ChainId.ROBIN_TESTNET]: [
  //   robTestnetTokens.cake,
  //   robTestnetTokens.btc,
  //   robTestnetTokens.bnb,
  //   robTestnetTokens.matic,
  //   robTestnetTokens.ada,
  //   robTestnetTokens.sol,
  //   robTestnetTokens.usdt,
  // ],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WBNB[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM]],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(30), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// A-RPG
export const DEFAULT_INPUT_CURRENCY = '0x71d9CFd1b7AdB1E8eb4c193CE6FFbe19B4aeE0dB' // RPG
// B-USDT
// '0x8E8816a1747fDDC5F8B45d2e140a425D3788f659' // mainnet
// '0x0F3A62dB02F743b549053cc8d538C65aB01E3618' // testnet
export const DEFAULT_OUTPUT_CURRENCY = '0x8E8816a1747fDDC5F8B45d2e140a425D3788f659'

export const USDT_CURRENCY = '0x8E8816a1747fDDC5F8B45d2e140a425D3788f659'

// Handler string is passed to Gelato to use PCS router
// export const GELATO_HANDLER = 'ponytaswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

// export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'
